
import { defineComponent } from "vue";
import { requiredField } from "@/util/form-rules";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import FormLoading from "../../layout/FormLoading.vue";
import Service from "@/services/admin/UserService";
import Disciplina from "@/interfaces/Disciplina";
import Turma from "@/interfaces/Turma";
import User from "@/interfaces/User";
import store from "@/store";
import useForm from "@/modules/useForm";
import Divisao from "@/interfaces/Divisao";

interface Form {
  nome: string;
  email: string;
  telefone: string | null;
  perfil_id: string;
  turmas: string[];
  disciplinas: string[];
  divisoes: string[];
}

const baseForm: Form = {
  nome: "",
  email: "",
  telefone: "",
  perfil_id: "",
  turmas: [],
  disciplinas: [],
  divisoes: [],
};

export default defineComponent({
  components: { FormLoading },
  props: {
    perfis: { type: Object, required: true },
    divisoes: { type: Object, required: true },
    disciplinas: { type: Object, required: true },
    turmas: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const { show, form, row, loading, submit, status, afterSubmit } = useForm(
      "users",
      "usuário",
      "o",
      baseForm,
      emit,
      "users"
    );

    const $q = useQuasar();

    const open = (userData: User): void => {
      row.value = JSON.parse(JSON.stringify(userData));
      if (row.value) {
        form.value = {
          nome: row.value.nome,
          email: row.value.email,
          telefone: row.value.telefone,
          perfil_id: row.value.perfil.id,
          turmas: row.value.turmas.map((t: Turma) => t.id),
          disciplinas: row.value.disciplinas.map((d: Disciplina) => d.id),
          divisoes: row.value.divisoes.map((d: Divisao) => d.id),
        };
        show.value = true;
      }
    };

    const resetPassword = (): void => {
      if (row.value) {
        loading.value = true;
        Service.resetPassword(row.value.id)
          .then(() => {
            afterSubmit();
            showNotify({
              type: "positive",
              message: "Senha do usuário resetada com sucesso",
            });
          })
          .catch((err) => {
            console.log(err);
            showNotify({
              type: "negative",
              message: "Erro ao resetar senha do usuário",
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const deleteUser = (): void => {
      $q.dialog({
        title: "Excluir Usuário",
        message: "Deseja realmente excluir o usuário?",
        cancel: "Cancelar",
        persistent: true,
      }).onOk(() => {
        if (row.value) {
          loading.value = true;
          Service.delete(row.value.id)
            .then(() => {
              afterSubmit();
              showNotify({
                type: "positive",
                message: "Usuário deletado com sucesso",
              });
            })
            .catch((err) => {
              console.log(err);
              showNotify({
                type: "negative",
                message: "Erro ao deletar usuário",
              });
            })
            .finally(() => (loading.value = false));
        }
      });
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    return {
      show,
      open,
      row,
      form,
      store,
      requiredField,
      loading,
      showNotify,
      submit,
      status,
      resetPassword,
      afterSubmit,
      deleteUser,
    };
  },
});
