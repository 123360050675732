import User from "@/interfaces/User";

const UserColumn = [
  {
    name: "actions",
    align: "center",
    label: "Ações",
    field: "",
    sortable: false,
  },
  {
    name: "nome",
    align: "left",
    label: "Nome",
    field: "nome",
    sortable: true,
  },
  {
    name: "email",
    align: "left",
    label: "E-mail",
    field: "email",
    sortable: true,
  },
  {
    name: "perfil",
    align: "center",
    label: "Perfil",
    field: (row: User) => row.perfil.descricao,
    sortable: true,
  },
  {
    name: "status",
    align: "center",
    label: "Status",
    field: "status",
    sortable: true,
  },
  {
    name: "created_at",
    align: "center",
    label: "Data Cadastro",
    field: (row: User) => row.created_at,
    sortable: true,
  },
];

export default UserColumn;
