
import { defineComponent } from "vue";
import { requiredField } from "@/util/form-rules";
import FormLoading from "../../layout/FormLoading.vue";
import useForm from "@/modules/useForm";
import store from "@/store";

interface Form {
  nome: string;
  email: string;
  telefone: string | null;
  password: string;
  passwordRepeat: string;
  turmas: string[];
  disciplinas: string[];
  perfil_id: string;
  divisoes: string[];
}

const baseForm: Form = {
  nome: "",
  email: "",
  telefone: "",
  password: "",
  passwordRepeat: "",
  turmas: [],
  disciplinas: [],
  perfil_id: "",
  divisoes: [],
};

export default defineComponent({
  components: { FormLoading },
  props: {
    perfis: { type: Object, required: true },
    divisoes: { type: Object, required: true },
    disciplinas: { type: Object, required: true },
    turmas: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const { show, form, row, loading, submit, destroy, afterSubmit } = useForm(
      "users",
      "usuário",
      "o",
      baseForm,
      emit,
      "users"
    );

    const open = () => {
      form.value = JSON.parse(JSON.stringify(baseForm));
      form.value.divisoes = store.getters.divisaoId ? [store.getters.divisaoId] : [];
      show.value = true;
    };

    return {
      show,
      open,
      form,
      row,
      submit,
      destroy,
      loading,
      afterSubmit,
      requiredField,
      store,
    };
  },
});
