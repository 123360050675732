
import { defineComponent, onMounted, ref } from "vue";
import UserColumn from "@/util/table-columns/UserColumn";
import UserForm from "@/components/admin/users/UserForm.vue";
import UserEdit from "@/components/admin/users/UserEdit.vue";
import useList from "@/modules/useList";
import store from "@/store";
import PerfilService from "@/services/admin/PerfilService";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import Perfil from "@/interfaces/Perfil";
import DivisaoService from "@/services/auth/DivisaoService";
import Divisao from "@/interfaces/Divisao";
import Turma from "@/interfaces/Turma";
import TurmaService from "@/services/admin/TurmaService";
import DisciplinaService from "@/services/admin/DisciplinaService";
import Disciplina from "@/interfaces/Disciplina";
import BaseService from "@/services/admin/BaseService";

export default defineComponent({
  components: { UserForm, UserEdit },
  setup() {
    const $q = useQuasar();
    const perfis = ref<Perfil[]>([]);
    const divisoes = ref<Divisao[]>([]);
    const turmas = ref<Turma[]>([]);
    const disciplinas = ref<Disciplina[]>([]);
    const {
      rows,
      loading,
      filter,
      columns,
      list,
      formatData,
      modalForm,
      openModalForm,
      modalEdit,
      openModalEdit,
    } = useList(
      store.getters.divisaoId
        ? "divisoes/users/" + store.getters.divisaoId
        : "users",
      UserColumn
    );

    const getPerfis = (): void => {
      loading.value = true;
      PerfilService.list()
        .then((res) => {
          perfis.value = res.data;
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const getTurmas = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        DivisaoService.getTurmas(store.getters.divisaoId)
          .then((res) => {
            turmas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        BaseService.list("turmas")
          .then((res) => {
            turmas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const getDisciplinas = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        DivisaoService.getDisciplinasSelect(store.getters.divisaoId)
          .then((res) => {
            disciplinas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        BaseService.list("disciplinas")
          .then((res) => {
            disciplinas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const getDivisoes = (): void => {
      loading.value = true;
      DivisaoService.list()
        .then((res) => {
          divisoes.value = res.data;
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    onMounted(() => {
      getPerfis();
      getTurmas();
      getDisciplinas();
      getDivisoes();
    });

    return {
      rows,
      loading,
      filter,
      columns,
      list,
      modalForm,
      modalEdit,
      openModalForm,
      openModalEdit,
      formatData,
      perfis,
      getPerfis,
      divisoes,
      getDivisoes,
      turmas,
      getTurmas,
      disciplinas,
      getDisciplinas,
    };
  },
});
